.share-reveal{
	height:280px;
	min-height: 280px;	
}

.reveal.small-reveal{	
	max-width:100%;
	padding: 70px 15px 26px;

	@media print, screen and (min-width: 48em) {
		width: 500px;
		padding-left: 26px;
		padding-right: 26px;		
	}

	[type="text"]{
		margin-top: 15px;
		font-size:14px;
		background-color:var(--color-1, #f5f5f5);
		border: 1px solid rgba(36, 34, 30, 0.2);
		padding: 0.5em;
		box-shadow: none;
		width: 100%;
		height: 2.75em;
	}
}

.title-share{
	text-align: center;
	padding: 0 0 15px 0;
	margin: 0;
	display: block;	
	text-transform: none;
	font-size:20px;
	line-height: 1.1;
	color:var(--color-title, #222);
	font-family: var(--font-family--font-title, 'Roboto', sans-serif);
	font-weight: 700;
	@media print, screen and (min-width: 36em) {
		font-size:24px;
		padding-bottom: 20px;
	}	
}

.close-button.close-button-share{
	position: absolute;
	width: 70px;
	height: 70px;
	top: 0;
	right: 0;
	display:block;
	text-align: center;	
	font-weight: 400;
	font-size:29px;	
	color:rgba(var(--color-title-rgb,0, 0, 0), 0.5);
	background: transparent;

	transition:color .5s ease;	
	
	&:before{
		content:'\f00d';
		font-family: var(--fa-style-family-classic);	
		font-weight: 400;
		font-size: inherit;
		position: absolute;
		top: 50%;
		right: 0;
		width: 100%;
		display: inline-block;
		text-align: center;
		line-height: 1;
		transform: translateY(-50%);		
	}
	
	&:hover{
		color:var(--color-title, #222);
	}	
}

.resp-sharing-button__icon {
  display: inline-block
}
.grid-sharing{
	display: flex;
	justify-content: center;
	align-items: center;
}
.cell-sharing{
	display: inline-block;
	width: auto;
}
a.resp-sharing-button__link, a.resp-sharing-button__link:focus { 	
	text-decoration: none;
  	margin:3px;	
	width: 48px;
	height: 48px;		
	position: relative;	
	display: block;
	transform: translate(0px, 0px);
	transition:transform .3s ease;
	font-weight: 400;
	letter-spacing: initial;
	text-align: center;			
	padding: 0;	
	transition: transform .25s ease-out; 	
	@media print, screen and (min-width: 36em) {
		 margin:4px;
		}

	.fa{
		font-size: 20px;
		color: inherit;
		letter-spacing: initial;
		position: absolute;	
		display: inline-block;
		line-height: 0;	
		left: 50%;	
		font-weight: 400!important;	
		top: 50%;
		transform: translate(-50%,-50%);			
	}
	//.resp-sharing-button__icon{}

	.resp-sharing-button{
		width: 48px;
		height: 48px;
		border-radius: 48px;
		position: relative;
		transition: border .5s ease, color .5s ease;
	}


	&:hover{
		transform: translate(0px, -5px);
		.resp-sharing-button{
			color:var(--color-title, #222);
			 border-color:var(--color-title, #222);
			  /*background-color:$color-2;*/}
		.resp-sharing-button--twitter { color: #2795e9; border-color:#2795e9; }		
		.resp-sharing-button--pinterest { color: #8c0615; border-color: #8c0615;}		
		.resp-sharing-button--facebook { color: #2d4373; border-color: #2d4373;}		
		.resp-sharing-button--tumblr { color: #222d3c; border-color: #222d3c;}		
		.resp-sharing-button--reddit { color: #3a80c1; border-color: #3a80c1;}		
		.resp-sharing-button--google { color: #c23321; border-color: #c23321;}		
		.resp-sharing-button--linkedin { color: #046293; border-color: #046293;}		
		.resp-sharing-button--email { color: #5e5e5e; border-color: #5e5e5e;}		
		.resp-sharing-button--clipboard { color: #5e5e5e; border-color: #5e5e5e;}		
		.resp-sharing-button--xing { color: #114c4c; border-color: #114c4c;}		
		.resp-sharing-button--whatsapp { color: #1da851; border-color: #1da851;}		
		.resp-sharing-button--hackernews{   color: #FB6200; border-color: #FB6200;}		
		.resp-sharing-button--vk{ color: #43648c; border-color: #43648c;}
	}
}

